import { Row, Col } from 'antd';
import { Icon } from '@mdi/react';
import { mdiLoading } from '@mdi/js';

import Logo from '@app/components/logo';
import PublicLayout from '@app/components/layouts/public-layout';

import styles from './loading.module.css';

export default function Loading(): JSX.Element {
  return (
    <PublicLayout pageTitle="loading">
      <Row>
        <Col flex="300px" className={styles.loadingWrapper}>
          <Logo theme="light" size={0.6} />
          <Icon path={mdiLoading} spin size={2} color="#0092ff" />
        </Col>
      </Row>
    </PublicLayout>
  );
}
