import { Layout } from 'antd';

const { Footer: AntFooter } = Layout;

export default function Footer({ className }: { className: string }): JSX.Element {
  return (
    <AntFooter className={className}>
      KCC Multiplex &copy; {new Date().getFullYear()} Developed by{' '}
      <a target="_blank" rel="noreferrer" href="https://smashydesign.com">
        Smashy Design
      </a>
    </AntFooter>
  );
}
