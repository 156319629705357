import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import Icon from '@mdi/react';
import {
  mdiTicketConfirmationOutline,
  mdiMovieRoll,
  mdiCalendarClockOutline,
  mdiTheater,
  mdiFileChartOutline,
  mdiLogoutVariant,
  mdiCogOutline,
  mdiCloseCircle,
} from '@mdi/js';
import { Layout as LayoutWrapper, Menu, Row, Col, Modal, Button } from 'antd';
import { useRouter } from 'next/router';

import { useAuth } from '@app/context/useAuth';
import Logo from '@app/components/logo';
import IconComp from '@app/components/icon';
import RestrictedContent from '@app/components/restricted-content';
import { UserRolesType } from '@app/types';
import { getBookingSettings } from '@app/lib/firestore';

import Loading from '../loading';

import styles from './layout.module.css';
import Footer from './footer';

const { Header, Content: AntContent } = LayoutWrapper;
export const siteTitle = (currentPage: string): string => `${currentPage} - KCC Multiplex`;

export type AllowedType = keyof UserRolesType;

export type LayoutPropType = {
  children: React.ReactNode;
  pageTitle?: string;
  allowed?: AllowedType[]; // page level access control
};

type IconPropType = {
  iconName: string;
  title: string;
};

const MenuIcon = ({ iconName, title }: IconPropType): JSX.Element => {
  return (
    <span role="img" aria-label={title} className={`anticon ant-menu-item-icon ${styles.menuicon}`}>
      <Icon path={iconName} title={title} size={1} color="#fff" />
    </span>
  );
};

export default function Layout({
  children,
  pageTitle = 'home',
  allowed = ['admin'],
}: LayoutPropType): JSX.Element {
  const { isReady, user, checkAuthorization } = useAuth();
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const [isRestricted, setIsRestricted] = useState(false);
  const [bookingDisabled, setBookingDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      const bookingSettings = await getBookingSettings();
      setBookingDisabled(bookingSettings.temporaryDisable);
    };
    // block pages if the user is not logged in
    if (isReady) {
      if (user) {
        // check if the person have access to this page
        const accessAllowed = checkAuthorization(allowed);
        if (accessAllowed) {
          setLoading(false);
          setIsRestricted(false);
          loadData();
        } else {
          // restricted area
          setLoading(false);
          setIsRestricted(true);
        }
      } else {
        // no user - redirect to login
        router.push('/login');
      }
    }
  }, [isReady, user, router, checkAuthorization, allowed]);

  const logoutFunction = () => {
    if (user?.roles.ticketAgent && !user?.roles.superManager && !user?.roles.manager && !user?.roles.admin && !user?.roles.accountant) {
      if (localStorage.getItem('floatInValue') && localStorage.getItem('floatInDate')) {
        showModal();
      }
    } else {
      localStorage.removeItem("floatInValue");
      localStorage.removeItem("floatInDate");
      router.push('/logout');
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("floatInValue");
    localStorage.removeItem("floatInDate");
    router.push('/logout');
  };

  const handlePrint = () => {
    setIsModalOpen(false);
    router.push('/tickets/agent-report');
  };

  return loading ? (
    <Loading />
  ) : (
    <div className="container">
      {isRestricted ? (
        <RestrictedContent />
      ) : (
        <Row>
          <Col span={24}>
            <Head>
              <title>{siteTitle(pageTitle)}</title>
              <link rel="preconnect" href="https://fonts.gstatic.com" />
              <meta name="robots" content="noindex" />
              <link
                href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400&display=swap"
                rel="stylesheet"
              />
              <script
                id="google-recaptcha"
                async
                src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_KEY}`}
              />
            </Head>
            <div className={styles.wrapper}>
              {bookingDisabled && (
                <div className={styles.disabledBookings}>
                  <IconComp
                    path={mdiCloseCircle}
                    style={{ marginRight: 10 }}
                    size={1}
                    color="#cf1322"
                  />
                  Booking has been disabled
                </div>
              )}
              <Header className={styles.header} style={bookingDisabled ? { top: 50 } : {}}>
                <div className={styles.innerHeader}>
                  <div className={styles.logo}>
                    <Logo style={{ margin: 0 }} size={0.5} />
                  </div>
                  <Menu
                    className={styles.menu}
                    theme="dark"
                    mode="horizontal"
                    selectedKeys={[router.asPath.substring(1).split('/')[0]]}>
                    <Menu.Item
                      icon={
                        <MenuIcon
                          // id="ticket"
                          title="ticket"
                          iconName={mdiTicketConfirmationOutline}
                        />
                      }
                      key="tickets"
                      hidden={user?.roles.accountant && !user?.roles.manager && !user?.roles.admin}
                      onClick={() => {
                        router.push('/tickets/book-tickets');
                      }}>
                      Tickets
                    </Menu.Item>
                    <Menu.Item
                      icon={<MenuIcon title="movies" iconName={mdiMovieRoll} />}
                      key="movies"
                      hidden={!user?.roles.manager}
                      onClick={() => {
                        router.push('/movies');
                      }}>
                      Movies
                    </Menu.Item>
                    <Menu.Item
                      icon={<MenuIcon title="Schedules" iconName={mdiCalendarClockOutline} />}
                      key="schedules"
                      hidden={!user?.roles.manager}
                      onClick={() => {
                        router.push('/schedules');
                      }}>
                      Schedules
                    </Menu.Item>
                    <Menu.Item
                      icon={<MenuIcon title="Cinemas" iconName={mdiTheater} />}
                      key="cinemas"
                      hidden={!user?.roles.admin}
                      onClick={() => {
                        router.push('/cinemas');
                      }}>
                      Cinemas
                    </Menu.Item>
                    <Menu.Item
                      icon={<MenuIcon title="Reports" iconName={mdiFileChartOutline} />}
                      key="reports"
                      hidden={!user?.roles.accountant}
                      onClick={() => {
                        router.push('/reports');
                      }}>
                      Reports
                    </Menu.Item>
                    <Menu.Item
                      icon={<MenuIcon title="Settings" iconName={mdiCogOutline} />}
                      key="settings"
                      hidden={!user?.roles.manager}
                      onClick={() => {
                        router.push('/settings/profile');
                      }}>
                      Settings
                    </Menu.Item>
                    {user?.uid && (
                      <Menu.Item
                        onClick={logoutFunction}
                        // onClick={() => {
                        //   router.push('/logout');
                        // }}
                        icon={<MenuIcon title="logout" iconName={mdiLogoutVariant} />}
                        key="logout">
                        Logout
                      </Menu.Item>
                    )}
                  </Menu>
                </div>
              </Header>
              <AntContent className={styles.content}>{children}</AntContent>
              <Footer className={styles.footer} />
            </div>
          </Col>
          <Modal
            title="Warning"
            visible={isModalOpen}
            footer={[
              <Button key="back" onClick={handleLogout}>
                Forced Logout
              </Button>,
              <Button key="submit" type="primary" onClick={handlePrint}>
                Print & Logout
              </Button>,
            ]}>
            <p>You have unprinted reports</p>
            <p>Do You Need To Print Reports or Logout without Printing</p>
            <p>You May Lose Data if You Logout</p>
          </Modal>
        </Row>
      )}
    </div>
  );
}
