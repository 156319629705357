import { Layout as LayoutWrapper, Row, Col } from 'antd';
import Head from 'next/head';

import styles from './layout.module.css';
import Footer from './footer';
import { siteTitle, LayoutPropType } from './layout';

const { Content } = LayoutWrapper;

export default function PublicLayout({
  children,
  pageTitle = 'home',
}: LayoutPropType): JSX.Element {
  return (
    <LayoutWrapper>
      <Head>
        <title>{siteTitle(pageTitle)}</title>
      </Head>
      <Row style={{ flex: 1 }}>
        <Col span={24}>
          <div className={styles.simpleWrapper}>
            <Content className={styles.simpleContent}>
              <Row className={styles.simpleContentInner} justify="center" align="middle">
                <Col>{children}</Col>
              </Row>
            </Content>
            <Footer className={styles.footer} />
          </div>
        </Col>
      </Row>
    </LayoutWrapper>
  );
}
