import { Result, Button } from 'antd';
import Head from 'next/head';
import { useRouter } from 'next/router';

export default function RestrictedContent(): JSX.Element {
  const router = useRouter();
  return (
    <>
      <Head>
        <title>Restricted Area - Access Denied</title>
      </Head>
      <Result
        status="error"
        title="Restricted Area - Access Denied"
        subTitle="Sorry! You don't have access to view this content."
        extra={[
          <Button
            type="primary"
            key="go-back"
            onClick={() => {
              router.back();
            }}>
            Go Back
          </Button>,
          <Button
            type="default"
            key="login"
            onClick={() => {
              router.push('/login');
            }}>
            Login
          </Button>,
        ]}
      />
    </>
  );
}
