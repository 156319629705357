import { Icon as MdiIcon } from '@mdi/react';
import { CSSProperties } from 'react';

type IconPropTypes = {
  /* Material Design Icon Path */
  path: string;
  size?: string | number | null;
  style?: CSSProperties;
  className?: string;
  spin?: boolean | number;
  color?: string;
};

export default function Icon({
  path,
  size,
  style,
  className,
  spin,
  color,
}: IconPropTypes): JSX.Element {
  return (
    <span role="img" className="anticon ant-menu-item-icon custom-icon">
      <MdiIcon
        path={path}
        size={size}
        style={style}
        className={className}
        spin={spin}
        color={color}
      />
    </span>
  );
}
